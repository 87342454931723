.Cards{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background: #fff;
    box-shadow: 0 8px 30px -7px #9ba2a7;
    margin: 10px;
    padding: 0 10px;
    border-radius: 10px;
    border: 0;
    height: 500px;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .card-img{
        display: flex;
        align-items: center;
        justify-content: center;

        img{
            border-radius: 10%;
            margin: 10px auto 0;
            // box-shadow: 0 8px 20px -4px #95abbb;
            width: 210px;
            
        }
    }

    .card-title{
        p{
            color: #000;
            font-size: 21px;
            line-height: 1.3;
            text-decoration: none;
        }
    }

    .active {
        opacity: 0.5;
        transition: all 0.3s;
    }

    .card-desc{
        p{
            margin-top: -10px;
            font-size: 18px;
            color: #555;
            padding-bottom: 15px;
            text-decoration: none;
        }
    }
    
    @media screen and (max-width:1610px) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 360px;
        width: 280px;
        .card-img{
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                border-radius: 10%;
                margin: 15px 10px 0;
                // box-shadow: 0 8px 20px -4px #95abbb;
                width: 150px;
                
            }
        }
        .card-title{
            p{
                color: #01b0f8;
                font-size: 18px;
                line-height: 1.3;
                text-decoration: none;
            }
        }

        .active {
            opacity: 0.5;
            transition: all 0.3s;
        }

        .card-desc{
            p{
                font-size: 18px;
                color: #555;
                margin-bottom: 15px;
                text-decoration: none;
            }
        }
    }
    @media screen and (max-width:1460px) {
        height: 370px;
        width: 250px;

        .card-img{
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                border-radius: 10%;
                margin: 15px 10px 0;
                // box-shadow: 0 8px 20px -4px #95abbb;
                width: 150px;
                
            }
        }
        .card-title{
            p{
                color: #01b0f8;
                font-size: 18px;
                line-height: 1.3;
                text-decoration: none;
            }
        }

        .active {
            opacity: 0.5;
            transition: all 0.3s;
        }

        .card-desc{
            p{
                font-size: 18px;
                color: #555;
                margin-bottom: 15px;
                text-decoration: none;
            }
        }
    }
    @media screen and (max-width: 700px) {
        height: 400px;
        width: 250px;
        margin-bottom: 50px;

        .card-img{
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                border-radius: 10%;
                margin: 15px 10px 0;
                // box-shadow: 0 8px 20px -4px #95abbb;
                width: 140px;
                
            }
        }
        
        .card-title{
            p{
                color: #01b0f8;
                font-size: 18px;
                line-height: 1.3;
                text-decoration: none;
            }
        }

        .active {
            opacity: 0.5;
            transition: all 0.3s;
        }

        .card-desc{
            p{
                font-size: 18px;
                color: #555;
                margin-bottom: 15px;
                text-decoration: none;
            }
        }
    }
}