.expert{
    height: 100%;
    .expertise{
        font-family: "Cardo", serif;
        font-size: 50px;
        text-align: start;
        margin-left: 80px;
        
        @media screen and (max-width: 700px) {
            margin-left: 0px;
            margin-right: 0px;
            font-size: 30px;
            margin-top: -100px;
            // margin-left: 40px;
            text-align: center;
        }

    }

    .main-cards{
        margin-left: 100px;
        margin-right: 100px;

        @media screen and (max-width:1460px) {
            margin-left: 40px;
            margin-right: 0px;
        }
        .cards{
            margin: 10px;
            display: grid;
            grid-template-columns: auto auto auto auto;
            place-items: center;
            
        }

        
        .space{
            height: 50px;
        }
        

        @media screen and (max-width:1200px) {
            margin-left: 40px;
            margin-right: 0px;
            .cards{
                margin: 10px;
                display: grid;
                grid-template-columns: auto auto auto;
                place-items: center;
                grid-row: span;
                
            }
        }
        @media screen and (max-width: 900px) {
            margin-left: 0px;
            margin-right: 0px;
            .cards{
                margin: 10px;
                display: grid;
                grid-template-columns: auto auto;
                place-items: center;
            }
            .space{
                display: none;
            }
        }


        @media screen and (max-width: 700px) {
            margin-left: 0px;
            margin-right: 0px;
            .cards{
                margin: 10px;
                display: grid;
                grid-template-columns: auto;
                place-items: center;
            }
            .space{
                display: none;
            }
        }

    }
}