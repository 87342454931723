.main-project{
    height: 100%;
    .proj-main-title{
        text-align: center;
        p{
            font-size: 55px;
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        }
    }
    .proj-cards{
        margin: 10px;
        display: grid;
        grid-template-columns: auto auto auto;
        place-items: center;

        
        @media screen and (max-width:1260px) {
            display: grid;
            grid-template-columns: auto auto ;
        }

        @media screen and (max-width:700px) {
            display: grid;
            grid-template-columns: auto ;
        }
        
    }
}