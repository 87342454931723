/* src/pages/ContactUsPage.css */
.contact-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .contact-us-container h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .contact-details {
    font-size: 1.2rem;
  }
  
  .contact-details p {
    margin: 10px 0;
  }
  
  .contact-details a {
    color: #007bff;
    text-decoration: none;
  }
  
  .contact-details a:hover {
    text-decoration: underline;
  }
  