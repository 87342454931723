.products-main{
    .prod-main-title{
        text-align: center;
        p{
            font-size: 55px;
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        }
    }
    p{
        font-size: large;
    }
}