.prod-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.prod-card a {
  text-decoration: none;
  color: inherit;
}

.image-cont {
  position: relative;
  overflow: hidden;
}

.image-cont img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.image-cont:hover img {
  transform: scale(1.1);
}

.content {
  padding: 10px;
}

.title {
  margin-top: 0;
}

.description {
  color: #666;
  font-size: 14px;
  line-height: 1.5;
}
