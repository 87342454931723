.WCards{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background: #fff;
    box-shadow: 0 8px 30px -7px #9ba2a7;
    margin: 0 20px;
    padding: 0 10px;
    border-radius: 20px;
    border: 0;
    height: 150px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    

    .wcard-img{
        display: flex;
        align-items: center;
        justify-content: center;

        img{
            border-radius: 10%;
            margin: 15px auto 0;
            // box-shadow: 0 8px 20px -4px #95abbb;
            width: 100px;
            
        }
    }

    .wcard-title{
        p{
            color: #000;
            font-size: 21px;
            line-height: 1.3;
            text-decoration: none;
        }
    }

    .active {
        opacity: 0.5;
        transition: all 0.3s;
    }

    
    @media screen and (max-width: 700px) {
        margin: 10px;
        margin-top: -5px;
        margin-bottom: -5px;

        .wcard-img{
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                border-radius: 10%;
                margin: 15px auto 0;
                // box-shadow: 0 8px 20px -4px #95abbb;
                width: 100px;
                
            }
        }

        .wcard-title{
            p{
                color: #000;
                font-size: 21px;
                line-height: 1.3;
                text-decoration: none;
            }
        }

        .active {
            opacity: 0.5;
            transition: all 0.3s;
        }
                
    }

    
}