.work-flow {
  height: 100%;
}

.work-flow .workflow {
  /* font-family: "Cardo", serif; */
  font-size: 50px;
  margin-left: 80px;
}

.work-flow .work {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 50px;
  position: relative;
}

.work-flow .work .arrow img {
  width: 50px;
}

@media screen and (max-width: 700px) {
  .work-flow .work .arrow {
    -webkit-transform: rotate(-270deg);
            transform: rotate(-270deg);
  }
}

.work-flow .work .img1 {
  width: 200px;
  position: relative;
}

.work-flow .work .img2 {
  width: 200px;
  position: relatives;
}

.work-flow .work .img3 {
  width: 200px;
  position: relative;
}

@media screen and (max-width: 1460px) {
  .work-flow .workflow {
    font-size: 50px;
    margin-left: 80px;
  }
  .work-flow .work {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

@media screen and (max-width: 700px) {
  .work-flow .workflow {
    font-size: 30px;
    margin-left: 0px;
    text-align: center;
  }
  .work-flow .work {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
