// @import url("https://fonts.googleapis.com/css2?family=Cardo:wght@700&display=swap");
.home {
    .why {
        height: 90vh;
        display: grid;
        grid-template-columns: 60% 40%;

        .one {
            padding: 15px;
            margin: 20px;
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .main-title {
                text-align: start;
                margin: 50px;
                font-size: 50px;
                margin-top: -0px;
                font-family: "Cardo", serif;
            }

            .main-content {
                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                margin: 50px;
                margin-top: -0px;
                font-size: 22px;
            }
        }
        .two {
            padding: 15px;
            margin: 20px;
            align-items: center;
            display: flex;
            justify-content: center;

            img {
                width: 600px;

                @media screen and (max-width: 700px) {
                    display: none;
                }
            }
        }

        @media screen and (max-width: 1460px) {
            height: 100%;
            display: grid;
            grid-template-columns: auto auto;

            .one {
                padding: 15px;
                margin: 20px;
                align-items: flex-start;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .main-title {
                    text-align: start;
                    font-size: 50px;
                    font-family: "Cardo", serif;
                    margin-bottom: -10px;
                    margin-top: 40px;
                }

                .main-content {
                    margin: 50px;
                    font-size: 20px;
                }
            }

            .two {
                padding: 15px;
                margin: 20px;
                align-items: center;
                display: flex;
                justify-content: center;

                img {
                    width: 500px;
                }
            }
        }

        @media screen and (max-width: 1100px) {
            height: 100%;
            display: grid;
            grid-template-columns: auto auto;
            .one {
                width: 100%;
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: start;

                .main-title {
                    padding: 0px;
                    margin: 0px;
                    width: 100%;
                    text-align: start;
                    font-size: 30px;
                    font-family: "Cardo", serif;
                    margin-bottom: 30px;
                }

                .main-content {
                    padding: 0px;
                    margin: 0px;
                    font-size: 20px;
                }
            }
            .two {
                padding: 15px;
                margin: 20px;
                align-items: center;
                display: flex;
                justify-content: center;

                img {
                    width: 400px;
                }
            }
        }
        

        @media screen and (max-width: 800px) {
            height: 100%;
            .one {
                width: 100%;
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: start;

                .main-title {
                    padding: 0px;
                    margin: 0px;
                    width: 100%;
                    text-align: start;
                    font-size: 25px;
                    font-family: "Cardo", serif;
                    margin-bottom: 30px;
                }

                .main-content {
                    padding: 0px;
                    margin: 0px;
                    font-size: 17px;
                }
            }
            .two {
                padding: 15px;
                margin: 20px;
                align-items: center;
                display: flex;
                justify-content: center;

                img {
                    width: 300px;
                }
            }
        }
        @media screen and (max-width: 700px) {
            margin-bottom: 30px;
            display: grid;
            grid-template-columns: 80%;
            .one {
                width: 100%;
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: start;

                .main-title {
                    padding: 0px;
                    margin: 0px;
                    width: 100%;
                    text-align: start;
                    font-size: 25px;
                    font-family: "Cardo", serif;
                    margin-bottom: 30px;
                }

                .main-content {
                    padding: 0px;
                    margin: 0px;
                    font-size: 17px;
                }
            }
        }
    }
}
