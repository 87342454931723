.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.footer .first {
  margin: auto;
}

.footer .heading {
  margin: 10px 0;
  color: aliceblue;
  font-size: smaller;
}

.footer img {
  width: 150px;
  margin-bottom: 20px;
}

.footer .footer-link {
  color: #fff;
  text-decoration: none;
  margin-top: 10px;
  display: inline-block;
}

.footer .footer-link:hover {
  text-decoration: underline;
}