.main-project {
  height: 100%;
}

.main-project .proj-main-title {
  text-align: center;
}

.main-project .proj-main-title p {
  font-size: 55px;
  /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
}

.main-project .proj-cards {
  margin: 10px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto auto;
      grid-template-columns: auto auto auto;
  place-items: center;
}

@media screen and (max-width: 1260px) {
  .main-project .proj-cards {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: auto auto;
        grid-template-columns: auto auto;
  }
}

@media screen and (max-width: 700px) {
  .main-project .proj-cards {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: auto;
        grid-template-columns: auto;
  }
}
