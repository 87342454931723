.about-main {
  height: 100px;
}

.about-main .main-title {
  /* font-family: "Cardo", serif; */
  font-size: 50px;
  text-align: start;
  margin-left: 80px;
}

.about-main .ACards-1 {
  margin-left: 100px;
  margin-right: 100px;
}

.about-main .ACards-1 .team-cards {
  margin: 20px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

@media screen and (max-width: 1610px) {
  .about-main .ACards-1 {
    margin: 0px;
  }
  .about-main .ACards-1 .team-cards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}

@media screen and (max-width: 1460px) {
  .about-main .ACards-1 {
    margin: 0px;
  }
  .about-main .ACards-1 .team-cards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}

@media screen and (max-width: 1260px) {
  .about-main .ACards-1 {
    margin: 0px;
  }
  .about-main .ACards-1 .team-cards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}

@media screen and (max-width: 800px) {
  .about-main .ACards-1 {
    margin: 0px;
  }
  .about-main .ACards-1 .team-cards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}

@media screen and (max-width: 600px) {
  .about-main .ACards-1 {
    margin: 0px;
  }
  .about-main .ACards-1 .team-cards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}

.about-us-container {
  margin: 0 auto;
  padding: 20px;
  /* font-family: Arial, sans-serif; */
}

h1 {
  font-size: 2em;
  color: #333;
  align-self: center;
}

h2 {
  font-size: 1.5em;
  color: #333;
}

p {
  color: #666;
  line-height: 1.5;
}

.company-overview {
  font-style: italic;
}

.team-member {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.team-member img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
}

.values-list,
.accomplishments-list,
.history-list {
  list-style-type: none;
  padding-left: 0;
}

.values-list li,
.accomplishments-list li,
.history-list li {
  margin-bottom: 10px;
}

.testimonial {
  font-style: italic;
  color: #888;
}

/* Add more styles as needed */
