/* src/pages/PricingPage.css */
.pricing-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
}

.pricing-container h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.pricing-table {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.pricing-card {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  margin: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.pricing-card h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.pricing-card .price {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.pricing-card ul {
  list-style-type: none;
  padding: 0;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.pricing-card ul li {
  margin-bottom: 10px;
}

.pricing-card .btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.pricing-card .btn:hover {
  background-color: #0056b3;
}
