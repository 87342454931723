.work-flow {
  height: 100%;

  .workflow {
    font-family: "Cardo", serif;
    font-size: 50px;
    margin-left: 80px;
  }

  .work {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    position: relative;

    .arrow {
      img {
        width: 50px;
      }

      @media screen and(max-width:700px) {
        transform: rotate(-270deg);
      }
    }

    .img1 {
      width: 200px;
      position: relative;
    }

    .img2 {
      width: 200px;
      position: relatives;
    }

    .img3 {
      width: 200px;
      position: relative;
    }
  }

  @media screen and (max-width: 1460px) {
    .workflow {
      font-size: 50px;
      margin-left: 80px;
    }

    .work {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media screen and (max-width: 700px) {
    .workflow {
      font-size: 30px;
      margin-left: 0px;
      text-align: center;
    }

    .work {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}
