.products-main .prod-main-title {
  text-align: center;
}

.products-main .prod-main-title p {
  font-size: 55px;
  /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
}

.products-main p {
  font-size: large;
}

.prod-cards-grid {
  display: grid;
  gap: 20px;
  margin: 0px 20px
}

.prod-cards-grid > * {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .prod-cards-grid {
      grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 767px) {
  .prod-cards-grid {
      grid-template-columns: 1fr;
  }
}
