/* PoliciesPage.css */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.policies-page {
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.policies-page h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
}

.policies-page section {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
}

.policies-page section h2 {
  background-color: #f0f0f0;
  margin: 0;
  padding: 10px;
  cursor: pointer;
  font-weight: 600;
}

.policies-page section .content {
  padding: 10px;
  background-color: #fff;
}

@media (max-width: 600px) {
  .policies-page {
    width: 95%;
    padding: 10px;
  }

  .policies-page h1 {
    font-size: 1.5rem;
  }

  .policies-page section h2 {
    font-size: 1rem;
    padding: 8px;
  }

  .policies-page section .content {
    padding: 8px;
    font-size: 0.9rem;
  }
}
