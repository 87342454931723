.ACards{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background: #fff;
    box-shadow: 8px 8px 30px -7px #b1dbd8;
    margin: 10px;
    padding: 0 10px;
    border: 0;
    height: 300px;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .social-icons{

        svg{
            color: black;
        }
    }

    .Acard-img{
        display: flex;
        align-items: center;
        justify-content: center;

        img{
            border-radius: 100%;
            margin: 10px auto 0;
            // box-shadow: 0 8px 20px -4px #95abbb;
            width: 120px;
            
        }
    }

    .Acard-name{
        p{
            color: #000;
            font-size: 21px;
            line-height: 1.3;
            text-decoration: none;
        }
    }

    .active {
        opacity: 0.5;
        transition: all 0.3s;
    }

    .Acard-desg{
        p{
            margin-top: -10px;
            font-size: 18px;
            color: #555;
            padding-bottom: 15px;
            text-decoration: none;
        }
    }
    
    @media screen and (max-width:1610px) {
        height: 300px;
        width: 250px;
        .Acard-img{
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                border-radius: 100%;
                margin: 15px 10px 0;
                // box-shadow: 0 8px 20px -4px #95abbb;
                width: 120px;
                
            }
        }
        .Acard-name{
            p{
                color: #01b0f8;
                font-size: 18px;
                line-height: 1.3;
                text-decoration: none;
                text-align: center;
            }
        }

        .active {
            opacity: 0.5;
            transition: all 0.3s;
        }

        .Acard-desg{
            p{
                font-size: 18px;
                color: #555;
                margin-bottom: 15px;
                text-decoration: none;
            }
        }
    }
    @media screen and (max-width:1460px) {
        height: 300px;
        width: 250px;

        .Acard-img{
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                border-radius: 100%;
                margin: 15px 10px 0;
                // box-shadow: 0 8px 20px -4px #95abbb;
                width: 120px;
                
            }
        }
        .Acard-name{
            p{
                color: #01b0f8;
                font-size: 18px;
                line-height: 1.3;
                text-decoration: none;
                text-align: center;
            }
        }

        .active {
            opacity: 0.5;
            transition: all 0.3s;
        }

        .Acard-desg{
            p{
                font-size: 18px;
                color: #555;
                margin-bottom: 15px;
                text-decoration: none;
            }
        }
    }
    @media screen and (max-width: 700px) {
        height: 300px;
        width: 250px;
        margin-bottom: 50px;

        .Acard-img{
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                border-radius: 100%;
                margin: 15px 10px 0;
                // box-shadow: 0 8px 20px -4px #95abbb;
                width: 120px;
                
            }
        }
        .Acard-name{
            p{
                color: #01b0f8;
                font-size: 18px;
                line-height: 1.3;
                text-decoration: none;
                text-align: center;
            }
        }

        .active {
            opacity: 0.5;
            transition: all 0.3s;
        }

        .Acard-desg{
            p{
                font-size: 18px;
                color: #555;
                margin-bottom: 15px;
                text-decoration: none;
            }
        }
    }
}