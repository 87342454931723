@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");
.main-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  justify-self: center;
  -ms-flex-item-align: center;
      align-self: center;
  margin-top: 30px;
}

@media screen and (max-width: 1200px) {
  .main-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    justify-self: center;
    -ms-flex-item-align: center;
        align-self: center;
    margin-top: 30px;
  }
}

@media screen and (max-width: 700px) {
  .main-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    justify-self: center;
    -ms-flex-item-align: center;
        align-self: center;
    margin-top: 30px;
  }
}

.main-menu .logo img {
  width: 125px;
  margin: 5px;
}

.main-menu .header {
  background-color: black;
  height: 50px;
  border-radius: 1rem;
  width: 70%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  list-style-type: none;
}

.main-menu .header .active {
  background-color: white;
  color: #000;
  padding: 8px;
  border-radius: 0.5rem;
}

.main-menu .header a {
  color: white;
  text-decoration: none;
  margin: 20px;
  /* font-family: "Varela Round", sans-serif; */
}

.main-menu .header a:hover {
  background-color: white;
  border-radius: 0.5rem;
  padding: 8px;
  color: #000;
}

@media screen and (max-width: 1200px) {
  .main-menu .header {
    display: none;
  }
}

.main-menu .hamburger {
  display: none;
}

@media screen and (max-width: 1200px) {
  .main-menu .hamburger {
    display: block;
    margin-right: 10px;
  }
}

.main-menu .hamburger-close {
  display: none;
}

@media screen and (max-width: 1200px) {
  .main-menu .hamburger-close {
    margin-right: 10px;
  }
}

.main-menu .social-icons {
  padding: 10px;
}

.main-menu .social-icons svg {
  margin: 10px;
  color: black;
}

@media screen and (max-width: 1460px) {
  .main-menu .social-icons svg {
    margin: 10px;
    color: black;
    width: 20px;
  }
}

@media screen and (max-width: 600px) {
  .main-menu .social-icons {
    display: none;
  }
}

.drop {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.drop .dropheader {
  width: 80%;
  background-color: black;
  color: white;
  border-radius: 10px;
  display: none;
}

@media screen and (max-width: 1460px) {
  .drop .dropheader {
    display: none;
  }
}

@media screen and (max-width: 860px) {
  .drop .dropheader {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    display: none;
  }
}

.drop .dropheader li {
  list-style-type: none;
  margin: 10px;
}

.drop .dropheader li a {
  text-decoration: none;
  color: white;
}

.drop .sicons {
  background-color: black;
  width: 80%;
  display: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.drop .sicons .social-icons {
  background-color: black;
  display: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.drop .sicons .social-icons a {
  color: white;
  margin: 10px;
  padding: 5px;
}

@media screen and (max-width: 1460px) {
  .drop .sicons .social-icons {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .drop .sicons {
    width: 80%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: stretch;
        align-content: stretch;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .drop .sicons .social-icons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}