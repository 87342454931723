/* @import url("https://fonts.googleapis.com/css2?family=Cardo:wght@700&display=swap"); */
.home .why {
  height: 90vh;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 60% 40%;
      grid-template-columns: 60% 40%;
}

.home .why .one {
  padding: 15px;
  margin: 20px;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.home .why .one .main-title {
  text-align: start;
  margin: 50px;
  font-size: 50px;
  margin-top: -0px;
  /* font-family: "Cardo", serif; */
}

.home .why .one .main-content {
  /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  margin: 50px;
  margin-top: -0px;
  font-size: 22px;
}

.home .why .two {
  padding: 15px;
  margin: 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.home .why .two img {
  width: 600px;
}

@media screen and (max-width: 700px) {
  .home .why .two img {
    display: none;
  }
}

@media screen and (max-width: 1460px) {
  .home .why {
    height: 100%;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: auto auto;
        grid-template-columns: auto auto;
  }
  .home .why .one {
    padding: 15px;
    margin: 20px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .home .why .one .main-title {
    text-align: start;
    font-size: 50px;
    /* font-family: "Cardo", serif; */
    margin-bottom: -10px;
    margin-top: 40px;
  }
  .home .why .one .main-content {
    margin: 50px;
    font-size: 20px;
  }
  .home .why .two {
    padding: 15px;
    margin: 20px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .home .why .two img {
    width: 500px;
  }
}

@media screen and (max-width: 1100px) {
  .home .why {
    height: 100%;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: auto auto;
        grid-template-columns: auto auto;
  }
  .home .why .one {
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .home .why .one .main-title {
    padding: 0px;
    margin: 0px;
    width: 100%;
    text-align: start;
    font-size: 30px;
    /* font-family: "Cardo", serif; */
    margin-bottom: 30px;
  }
  .home .why .one .main-content {
    padding: 0px;
    margin: 0px;
    font-size: 20px;
  }
  .home .why .two {
    padding: 15px;
    margin: 20px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .home .why .two img {
    width: 400px;
  }
}

@media screen and (max-width: 800px) {
  .home .why {
    height: 100%;
  }
  .home .why .one {
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .home .why .one .main-title {
    padding: 0px;
    margin: 0px;
    width: 100%;
    text-align: start;
    font-size: 25px;
    /* font-family: "Cardo", serif; */
    margin-bottom: 30px;
  }
  .home .why .one .main-content {
    padding: 0px;
    margin: 0px;
    font-size: 17px;
  }
  .home .why .two {
    padding: 15px;
    margin: 20px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .home .why .two img {
    width: 300px;
  }
}

@media screen and (max-width: 700px) {
  .home .why {
    margin-bottom: 30px;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 80%;
        grid-template-columns: 80%;
  }
  .home .why .one {
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .home .why .one .main-title {
    padding: 0px;
    margin: 0px;
    width: 100%;
    text-align: flex-start;
    font-size: 25px;
    /* font-family: "Cardo", serif; */
    margin-bottom: 30px;
  }
  .home .why .one .main-content {
    padding: 0px;
    margin: 0px;
    font-size: 17px;
  }
}
