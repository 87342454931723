.ACards {
  /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  background: #fff;
  -webkit-box-shadow: 8px 8px 30px -7px #b1dbd8;
          box-shadow: 8px 8px 30px -7px #b1dbd8;
  margin: 10px;
  padding: 0 10px;
  border: 0;
  height: 300px;
  width: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.ACards .social-icons svg {
  color: black;
}

.ACards .Acard-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.ACards .Acard-img img {
  border-radius: 100%;
  margin: 10px auto 0;
  width: 120px;
}

.ACards .Acard-name p {
  color: #000;
  font-size: 21px;
  text-decoration: none;
}

.ACards .active {
  opacity: 0.5;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.ACards .Acard-desg p {
  margin-top: -10px;
  font-size: 18px;
  color: #555;
  text-decoration: none;
}

@media screen and (max-width: 1610px) {
  .ACards {
    height: 300px;
    width: 250px;
  }
  .ACards .Acard-img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .ACards .Acard-img img {
    border-radius: 100%;
    margin: 15px 10px 0;
    width: 120px;
  }
  .ACards .Acard-name p {
    color: #01b0f8;
    font-size: 18px;
    line-height: 1.3;
    text-decoration: none;
    text-align: center;
  }
  .ACards .active {
    opacity: 0.5;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ACards .Acard-desg p {
    font-size: 18px;
    color: #555;
    margin-bottom: 15px;
    text-decoration: none;
  }
}

@media screen and (max-width: 1460px) {
  .ACards {
    height: 300px;
    width: 250px;
  }
  .ACards .Acard-img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .ACards .Acard-img img {
    border-radius: 100%;
    margin: 15px 10px 0;
    width: 120px;
  }
  .ACards .Acard-name p {
    color: #01b0f8;
    font-size: 18px;
    line-height: 1.3;
    text-decoration: none;
    text-align: center;
  }
  .ACards .active {
    opacity: 0.5;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ACards .Acard-desg p {
    font-size: 18px;
    color: #555;
    margin-bottom: 15px;
    text-decoration: none;
  }
}

@media screen and (max-width: 700px) {
  .ACards {
    height: 300px;
    width: 250px;
    margin-bottom: 50px;
  }
  .ACards .Acard-img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .ACards .Acard-img img {
    border-radius: 100%;
    margin: 15px 10px 0;
    width: 120px;
  }
  .ACards .Acard-name p {
    color: #01b0f8;
    font-size: 18px;
    line-height: 1.3;
    text-decoration: none;
    text-align: center;
  }
  .ACards .active {
    opacity: 0.5;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ACards .Acard-desg p {
    font-size: 18px;
    color: #555;
    margin-bottom: 15px;
    text-decoration: none;
  }
}
